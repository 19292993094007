import React from "react";
import Layout from "layout";
import Hero from "common/hero";
import { Container, Row, Col } from "react-grid-system";
import { TypeA, TypeB } from "common/callouts";
import useDeviceSize from "libs/hooks/useDeviceSize";
import Seo from "common/seo";
import heroImageRef from "images/about/hero-about.webp";
import img1 from "images/icons/icon-clincal-studies.png";
import img2 from "images/icons/icon-foundation.png";
import img3 from "images/icons/icon-patents.png";
import img4 from "images/about/img-our-science.webp";
import img5 from "images/about/img-our-innovation.webp";
import img6 from "images/about/img-our-philanthropy.webp";
import img7 from "images/callouts/sunproducts2x.webp";
import img8 from "images/callouts/cout-footer-resources.webp";

import "./aboutus.scss";

function AboutUsPage({ pageContext }) {
  const deviceSizes = useDeviceSize();
  return (
    <Layout
      pageClass="page-aboutus"
      breadcrumbData={pageContext.breadcrumb}
      hero={<MyHero />}
    >
      <Seo pageTitle="About Us" pageDescription="Learn about Beiersdorf" />
      <div className="inner-body-content">
        <section className="inner-centered-container">
          <Row>
            <Col sm={12}>
              <h1 className="text-center top-header">
                A legacy of innovation for over 100 years
              </h1>
              <p className="text-center">
                At Beiersdorf, our passion and expertise are focused on
                discovering the next innovation that helps protect, repair, and
                sustain skin health throughout life’s journey. Our goal is to
                develop therapeutic skin care products that are trusted by
                healthcare professionals and recommended to their patients every
                day.
              </p>
            </Col>
          </Row>
        </section>
        <div className="gradient-bg gray-grad top-section">
          <section className="inner-centered-container">
            <Row className="text-center">
              <Col sm={12} md={4} className={deviceSizes?.smUp ? "no-px" : ""}>
                <img
                  src={img1}
                  alt="icon for clinical studies"
                  className="img-center img1"
                />
                <p className="about-facts">
                  &gt;1150 clinical studies conducted worldwide with 35,000
                  participants
                </p>
              </Col>
              <Col sm={12} md={4} className={deviceSizes?.smUp ? "no-px" : ""}>
                <img
                  src={img2}
                  alt="icon for partnership with the foundation"
                  className="img-center img2"
                />
                <p className="about-facts">
                  A partnership with the Foundation for Ichthyosis and Related
                  Skin Types (FIRST) is vital to our global community outreach
                  to reduce stigma and promote inclusion
                </p>
              </Col>
              <Col sm={12} md={4} className={deviceSizes?.smUp ? "no-px" : ""}>
                <img
                  src={img3}
                  alt="icon for patents filed"
                  className="img-center img3"
                />
                <p className="about-facts last">75 patents filed in 2020</p>
              </Col>
            </Row>
          </section>
        </div>
        <section className="inner-centered-container img-sz">
          <Row>
            <Col
              sm={12}
              md={6}
              order={{ xs: 2, md: 1 }}
              className={deviceSizes?.smUp ? "no-pr" : ""}
            >
              <TypeB
                head1="Our science"
                buttonText="SEE THE RESEARCH"
                buttonUrl="https://www.beiersdorf.com/research/overview"
                isExternalLink={true}
                targetOpen="_blank"
                className="center-v cout-right-condensed content-wrap"
              >
                We have close to 900 scientists dedicated to research and
                product development, as well as a research center focused on
                setting industry-wide global standards for measuring, analyzing,
                and evaluating product effectiveness. But our dedication extends
                further than that. We are also committed to the field of skin
                research to better understand the skin’s biology and the basic
                biological, chemical, and physical principles of optimal skin
                treatment.
              </TypeB>
            </Col>
            <Col
              sm={12}
              md={6}
              order={{ xs: 1, md: 2 }}
              className={deviceSizes?.smUp ? "no-px img-move" : ""}
            >
              <img
                src={img4}
                alt="Research holding a research sample"
                className="img-our-science"
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6} className={deviceSizes?.smUp ? "no-px" : ""}>
              <img
                src={img5}
                alt="Zoomed skin cell/bubble forms showing innovation"
                className="img-innovation"
              />
            </Col>
            <Col sm={12} md={6} className={deviceSizes?.smUp ? "no-pr" : ""}>
              <TypeB
                head1="Our innovation"
                buttonText="EXPLORE OUR HISTORY"
                buttonUrl="https://www.beiersdorf.com/about-us/our-history/milestones"
                isExternalLink={true}
                targetOpen="_blank"
                className="center-v cout-right-condensed content-wrap innovation"
              >
                Innovation has been at the heart of our business since 1900,
                when the first patent for the hydrophilic ointment base in
                Eucerin<sup>&reg;</sup> was submitted. This focus on decoding
                the skin’s complex processes, researching the effects of certain
                substances on human skin, and developing new product
                formulations continues to this day. We are pushing skin care
                forward with each introduction of a global standard and
                discovery of another active ingredient.
              </TypeB>
            </Col>
          </Row>
          <Row>
            <Col
              sm={12}
              md={6}
              order={{ xs: 2, md: 1 }}
              className={deviceSizes?.smUp ? "no-pr" : ""}
            >
              <TypeB
                head1="Our philanthropy"
                buttonText="Learn more about first"
                buttonUrl="https://www.firstskinfoundation.org/"
                isExternalLink={true}
                targetOpen="_blank"
                className="center-v cout-right-condensed content-wrap"
              >
                Our care goes beyond skin. From community outreach and relief to
                sustainability efforts, we take initiative for the things that
                really matter. That’s why we are proud to partner with the
                Foundation for Ichthyosis and Related Skin Types (FIRST). From
                donating products to families in need, to joining their “Make a
                Difference” initiative, to promoting social inclusion, we’re
                dedicated to ensuring that skin care is a community-wide
                priority.
              </TypeB>
            </Col>
            <Col
              sm={12}
              md={6}
              order={{ xs: 1, md: 2 }}
              className={deviceSizes?.smUp ? "no-px" : ""}
            >
              <img
                src={img6}
                alt="Holding hands showing philanthropy"
                className="img-philanthropy"
              />
            </Col>
          </Row>
        </section>
        <section className="callouts-container gradient-bg blue-grad">
          <div className="inner-centered-container">
            <Row>
              <Col sm={12} md={6}>
                <TypeA
                  imgRef={img7}
                  imgAlt="Explore Sun Care"
                  caption="Sun protection for all skin types<br class='show-desktop'> and tones"
                  buttonText="Explore Sun Care"
                  buttonUrl="/sun"
                  imgStyles={{ width: 236 }}
                />
              </Col>
              <Col sm={12} md={6}>
                <TypeA
                  imgRef={img8}
                  imgAlt="Request samples"
                  caption="Have skin care products on hand<br class='show-desktop'> for patients to take home"
                  buttonText="Request samples"
                  buttonUrl="https://bdf.hcpsampling.com"
                  isExternalLink={true}
                  targetOpen="_blank"
                  imgStyles={{ height: 158 }}
                />
              </Col>
            </Row>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default AboutUsPage;

const MyHero = () => <Hero className="about-hero" bgImagePath={heroImageRef} />;
